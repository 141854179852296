.contact-page {
  text-align: center;
  max-width: 600px;
  width: 100%;
  border: var(--secondary) 4px solid;
  margin: 2em auto;
  position: relative;
  color: var(--primary);
}

.contact-page h1 {
  font-size: calc(1.5em + 1vw);
  margin: 20px 0;
  font-weight: 900;
  color: var(--secondary);
}

.contact-page::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(https://letsliftitup.files.wordpress.com/2015/10/hands.jpg);
  background-position: center;
  background-size: cover;
  filter: contrast(0.7) brightness(0.5);
  opacity: 0.5;
  z-index: -1;
}

form {
  text-align: center;
  padding: 0 1em;
}

form label {
  display: flex;
  font-size: 1.25em;
  padding: 0 8px;
  margin-bottom: -30px;
  background-color: var(--secondary);
  border: var(--primary) 1px solid;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

form input {
  width: 100%;
  margin: 30px 0;
  padding: 10px;
  border: 1px solid var(--primary);
  border-radius: 0 4px 4px 0px;
  font-size: 1em;
  position: relative;
}

form textarea {
  width: 100%;
  margin: 30px 0;
  padding: 10px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  font-size: 1em;
  resize: none;
}

#namesWrapper {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.names {
  justify-content: center;
  display: flex;
  width: 100%;
  position: relative;
  margin-bottom: 16px;
}

/* .names:nth-of-type(2) input {
  border-radius: 4px;
} */

.names span {
  position: absolute;
  border-radius: 0 0 4px 4px;
  border: var(--primary) 1px solid;
  padding: 0 8px;
  left: 0px;
  bottom: 10px;
  z-index: 1;
  background-color: var(--secondary);
  color: var(--primary);
}

#subscribe {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 30px auto;
  background-color: var(--secondary);
  border-bottom: var(--primary) 1px solid;
  width: fit-content;
}

#subscribe label {
  margin: 0;
  padding: 1em;
  border-radius: 4px;
  background-color: var(--secondary);
  align-items: center;
  gap: 16px;
}

#subscribe button {
  margin: 0;
  width: 29px;
  height: 20px;
  align-items: center;
  z-index: 1;
  background-color: var(--secondary);
  border: var(--primary) 2px solid;
  cursor: pointer;
}

#submitWrapper {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin: 30px 0;
}

#submitWrapper button {
  padding: 10px 20px;
  border: var(--secondary) 1px solid;
  border-radius: 4px;
  background-color: var(--secondary);
  color: var(--primary);
  font-size: 1em;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: var(--primary);
    color: var(--secondary);
  }
}

.error {
  width: 100%;
  border: 2px solid red;
  border-radius: 4px;
  padding: 10px;
  background-color: white;
  color: black;
}

.success {
  width: 100%;
  border: 2px solid green;
  border-radius: 4px;
  padding: 10px;
  background-color: white;
  color: black;
}
